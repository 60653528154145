
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Grid, Card, IconButton, Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import DataTable from '../../examples/Tables/DataTable';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { enqueueSnackbar } from 'notistack';

interface Chamber {
  chamber_id: number;
  first_name: string;
  last_name: string;
  phone_number: string;
  email: string;
  payment: string;
  organization_name: string;
  organization_category: string;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const Chamber: React.FC = () => {
  const [chambers, setChambers] = useState<Chamber[]>([]);
  const setLoading = useState<boolean>(false)[1];
  const navigate = useNavigate();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [chamberToDeleteId, setChamberToDeleteId] = useState<number | null>(null);
  const authToken = localStorage.getItem("token");

  const fetchAllChambers = async () => {
    setLoading(true);
  
    try {
      const response = await axios.get<{ success: boolean; data: Chamber[] }>(`${process.env.REACT_APP_BASEURL}/chamber`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (response.data.success) {
        setChambers(response.data.data);
        console.log(authToken);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  
    setLoading(false);
  };
  
  useEffect(() => {
    const currentPath = window.location.pathname;
  
    if (currentPath === '/chamber') {
      fetchAllChambers();
    }
// eslint-disable-next-line
  },[]);

  const columns = [
    { Header: 'Chamber ID', accessor: 'chamber_id' },
    { Header: 'First Name', accessor: 'first_name' },
    { Header: 'Last Name', accessor: 'last_name' },
    { Header: 'Phone Number', accessor: 'phone_number' },
    { Header: 'Email', accessor: 'email' },
    { Header: 'payment', accessor: 'payment'},
    { Header: 'Organization', accessor: 'organization_name' },
    { Header: 'Org Category', accessor: 'organization_category' },
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: ({ row }: { row: { original: Chamber }}) => (
        <div>
          <IconButton
            color="primary"
            aria-label="edit"
            onClick={() => handleEdit(row.original.chamber_id)}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            color="secondary"
            aria-label="delete"
            onClick={() => handleDelete(row.original.chamber_id)}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const handleDelete = (chamber_id: number) => {
    setChamberToDeleteId(chamber_id);
    setDeleteDialogOpen(true);
  };
  
  const handleEdit = (chamber_id: number) => {
    navigate('/chamber/edit', { state: { type: 'edit', chamber_id } });
  };
  
  
  const confirmDelete = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_BASEURL}/chamber/${chamberToDeleteId }`, 
        { 
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      
      enqueueSnackbar("Chamber deleted successfully!", { variant: 'success' });
  
      setChambers((prevChambers) => prevChambers.filter(chamber => chamber.chamber_id !== chamberToDeleteId));
    } catch (error) {
      enqueueSnackbar('Error deleting chamber:', { variant: 'error' });
    }
  
    setDeleteDialogOpen(false);
  };
  
  
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box pt={6} pb={3}>
        <Grid container spacing={6} justifyContent="flex-start">
          <Grid item xs={12}>
            <Card>
            <Box
              mx={2}
              mt={-3}
              py={3}
              px={2}
              sx={{
                bgcolor: 'info.main',
                borderRadius: '12px',
                boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(255,152,0,0.4)',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
                <Typography variant="h6" color="#ffffff">
                  Chamber
                </Typography>
                <Box className="Chamber_btnsHeaderCont">
                <Button onClick={() => navigate('/chamber/add')} variant="outlined" color="inherit">
                    Add Chamber
                </Button>
                </Box>
              </Box>
              <Box pt={3}>
              {chambers && (
                  <DataTable
                    table={{ columns, rows: chambers }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                )}
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
      <DialogTitle>Confirm Deletion</DialogTitle>
      <DialogContent>
        Are you sure you want to delete this chamber?
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDeleteDialogOpen(false)} color="secondary">
          Cancel
        </Button>
        <Button onClick={confirmDelete} color="primary">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
    </DashboardLayout>
  );
}

export default Chamber;
