import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import axios from "axios";

function Overview() {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const user_id = localStorage.getItem("user_id");
  const authToken = localStorage.getItem("token");
  const role = localStorage.getItem("role");

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASEURL}/chamber/${user_id}`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        if (response.data.success) {
          setUserData(response.data.data);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    setLoading(false);
    fetchUserData();
  }, [user_id, authToken]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!userData) {
    return <div>Error fetching user data</div>;
  }

  const { first_name, last_name, email } = userData;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3} {...({} as any)}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                {...({} as any)}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <MDTypography variant="h6" color="white" {...({} as any)}>
                    My Profile
                  </MDTypography>
                </Grid>
              </MDBox>
              <MDBox pt={3} px={2} {...({} as any)}>
                <MDBox mb={3}{...({} as any)}>
                  <MDTypography variant="outline" {...({} as any)}>Name: {first_name} {last_name}</MDTypography>
                </MDBox>
                <MDBox mb={3}{...({} as any)}>
                  <MDTypography variant="outline" {...({} as any)}>Role: {role}</MDTypography>
                </MDBox>
                <MDBox mb={5} {...({} as any)}>
                  <MDTypography variant="outline" {...({} as any)}>Email: {email}</MDTypography>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Overview;
