export const actionTypes = {
    GETUSER_SUCCESS: 'getUserSuccess',
    GETUSER_LOADING: 'getUserLoading',
    GETUSER_ERROR: 'getUserError',

    GETUSERBYID_SUCCESS: 'getUserByIdSuccess',
    GETUSERBYID_LOADING: 'getUserByIdLoading',
    GETUSERBYID_ERROR: 'getUserByIdError',

    ADDUSER_SUCCESS: 'addUserSuccess',
    ADDUSER_LOADING: 'addUserLoading',
    ADDUSER_ERROR: 'addUserError',

    UPDATEUSER_SUCCESS: 'updateUserSuccess',
    UPDATEUSER_LOADING: 'updateUserLoading',
    UPDATEUSER_ERROR: 'updateUserError',

    DELETEUSER_SUCCESS: 'deleteUserSuccess',
    DELETEUSER_LOADING: 'deleteUserLoading',
    DELETEUSER_ERROR: 'deleteUserError'
}