import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";

// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "./context";
import { Provider } from "react-redux";
import { store } from "./Redux/store";
import { SnackbarProvider } from 'notistack';

// Ensure container exists before attempting to render
const container = document.getElementById("app");
if (!container) {
  throw new Error('Container element with ID "app" not found');
}

const root = createRoot(container);

root.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <SnackbarProvider>
        <Provider store={store}>
          <App />
        </Provider>
      </SnackbarProvider>
    </MaterialUIControllerProvider>
  </BrowserRouter>
);
