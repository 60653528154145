import { ChangeEvent, useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import axios from "axios";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDInput from "../../components/MDInput";
import MDButton from "../../components/MDButton";

// Authentication layout components
import BasicLayout from "../../layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

function SignIn(): JSX.Element {

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const email = params.get('email');
    const password = params.get('password');

    if (email && password) {
      setLoading(true);
      const login = async () => {
        try {
          const response = await axios.post<SignInResponse>(`${process.env.REACT_APP_BASEURL}/chamber/login`, {
            email,
            password
          });
          const { accessToken, user_id } = response.data.message;
          localStorage.setItem("isLoggedIn", "true");
          localStorage.setItem("role", "superadmin");
          localStorage.setItem("user_id", user_id);
          localStorage.setItem("token", accessToken);
          enqueueSnackbar('sign-in successful', { variant: 'success' });
          navigate('/dashboard');
        } catch (error) {
          console.error('Sign-in error:', error);
          enqueueSnackbar('Error during sign-in', { variant: 'error' });
        } finally {
          setLoading(false);
        }
      };

      login();
    }
  }, []);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  interface SignInResponse {
    message: any;
  }

  const onSignIn = async (): Promise<void> => {
    try {
      setLoading(true);

      const response = await axios.post<SignInResponse>(`${process.env.REACT_APP_BASEURL}/chamber/login`, {
        email,
        password
      });

      const { accessToken, user_id } = response.data.message;
      localStorage.setItem("isLoggedIn", "true");
      localStorage.setItem("role", "superadmin");
      localStorage.setItem("user_id", user_id);
      localStorage.setItem("token", accessToken);
      enqueueSnackbar('sign-in successful', { variant: 'success' });
      navigate('/dashboard');
    } catch (error) {
      console.error('Sign-in error:', error);
      enqueueSnackbar('Error during sign-in', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          textAlign="center"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          {...({} as any)}
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" {...({} as any)}>
            Sign in
          </MDTypography>
        </MDBox>

        <MDBox pt={4} pb={3} px={3} {...({} as any)}>
          <MDBox component="form" role="form" {...({} as any)}>
            <MDBox mb={2} {...({} as any)}>
              <MDInput
                type="email"
                label="Email"
                fullWidth
                value={email}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                {...({} as any)}
              />
            </MDBox>
            <MDBox mb={2} {...({} as any)}>
              <MDInput
                type="password"
                label="Password"
                fullWidth
                value={password}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                {...({} as any)}
              />
            </MDBox>
            <MDBox mt={4} mb={1} {...({} as any)} >
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={onSignIn}
                {...({} as any)}
              >
                {loading ? (
                  <CircularProgress
                    size={20}
                    sx={{ color: "inherit" }}
                  />
                ) : (
                  "Sign in"
                )}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default SignIn;
