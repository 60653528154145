import React from "react";
import { useNavigate } from "react-router-dom";
import Icon from "@mui/material/Icon";
import Dashboard from "./layouts/dashboard";
import Profile from "./layouts/profile";
import SignIn from "./layouts/SignIn/signIn";
import AddChamber from "./layouts/chamber/AddChamber";
import Chamber from './layouts/chamber';
import SignOut from "./Signout";
import Templates from "./layouts/templates";

interface Route {
  type?: string;
  name?: string;
  key?: string;
  icon?: JSX.Element;
  route: string;
  component: JSX.Element;
  onClick?: () => void;
}

const useSignOut = () => {
  const navigate = useNavigate();

  const handleSignOut = () => {
    localStorage.clear();
    // navigate("/sign-in");
      window.location.href = `https://app.pixacci.com/landing-page`;
  };

  return handleSignOut;
};

const routes = (): {
  superAdminRoutes: Route[];
  // AdminRoutes: Route[];
  // userRoutes: Route[];
  notLoggedInRoutes: Route[];
} => {
  const commonRoutes: Route[] = [
    {
      type: "collapse",
      name: "Dashboard",
      key: "dashboard",
      icon: <Icon fontSize="small">dashboard</Icon>,
      route: "/dashboard",
      component: <Dashboard />,
    },
    {
      type: "collapse",
      name: "Chamber",
      key: "chamber",
      icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
      route: "/chamber",
      component: <Chamber />,
    },
    {
      type: "collapse",
      name: "Templates",
      key: "template",
      icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
      route: "/template",
      component: <Templates />,
    },
    {
      // type: "collapse",
      // name: "Add Chamber",
      key: "add_chamber",
      // icon: <Icon fontSize="small">add_circle</Icon>,
      route: "/chamber/add",
      component: <AddChamber />,
    },
    {
      // type: "collapse",
      // name: "Edit Chamber",
      key: "edit_chamber",
      // icon: <Icon fontSize="small">edit</Icon>,
      route: "/chamber/edit",
      component: <AddChamber />,
    },
    {
      type: "collapse",
      name: "Profile",
      key: "profile",
      icon: <Icon fontSize="small">person</Icon>,
      route: "/profile",
      component: <Profile />,
    },
    {
      type: "collapse",
      name: "Sign Out",
      key: "sign-in",
      icon: <Icon fontSize="small">logout</Icon>,
      route: "/sign-out",
      onClick: useSignOut,
      component: <SignOut />,
    },
  ];

  const notLoggedInRoutes: Route[] = [
    {
      type: "collapse",
      name: "Sign In",
      key: "sign-in",
      icon: <Icon fontSize="small">login</Icon>,
      route: "/sign-in",
      component: <SignIn />,
    },
  ];

  return {
    superAdminRoutes: commonRoutes,
    // AdminRoutes: commonRoutes,
    // userRoutes: commonRoutes,
    notLoggedInRoutes,
  };
};

export default routes;
