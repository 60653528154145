import { actionTypes } from "./action.types"
const initialState = {
    loading: false,
    error: false,
    user: [],
    userById: {}
}

const Reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.ADDUSER_LOADING:
            return { ...state, loading: true, error: false }
        case actionTypes.ADDUSER_SUCCESS:
            return { ...state, loading: false, error: false, user: [payload, ...state.user] }
        case actionTypes.ADDUSER_ERROR:
            return { ...state, loading: false, error: true }
        case actionTypes.GETUSER_LOADING:
            return { ...state, loading: true, error: false }
        case actionTypes.GETUSER_SUCCESS:
            return { ...state, loading: false, user: payload }
        case actionTypes.GETUSER_ERROR:
            return { ...state, loading: false, error: true }
        case actionTypes.GETUSERBYID_LOADING:
            return { ...state, loading: true, error: false }
        case actionTypes.GETUSERBYID_SUCCESS:
            return { ...state, loading: false, userById: payload }
        case actionTypes.GETUSERBYID_ERROR:
            return { ...state, loading: false, error: true }
        // case types.DELETENOTES: {
        //     let x = state.notes.filter((item) => {
        //         return item.id !== payload.id
        //     })
        //     return { ...state, notes: x }
        // }
        // case types.UPDATENOTES: {
        //     return {
        //         ...state, notes: state.notes.map((item) =>
        //             item.id === payload.id ? payload : item)
        //     }
        // }
        default:
            return state
    }
}
export default Reducer